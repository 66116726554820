import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { ListUsers, User } from "../components/staff/listUsers";
import { CreateUser } from "../components/staff/createUser";
import { AdministrationUserApi } from "../lib/apiActions";

interface ComponentProps {
}

const StaffView: React.FC<ComponentProps> = (props) => {
    // const [open, setOpen] = useState(false);
    const [users, setUsers] = useState<User[] | null>(null);

    const fetchUsers = async () => {
        try {
            const response = await AdministrationUserApi.get(null);
            if (response.status >= 300) {
                console.error('Error fetching users:', response);
                return;
            }
            setUsers(response.data);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    return (
        <Box sx={{
            width: { xs: "100vw", sm: "80vw", },
            height: { xs: "100vh", sm: "70vh", },
            overflow: "auto", 
        }}>
            <CreateUser fetchUsers={fetchUsers}/>
            <ListUsers 
                users={users}
                // open={open}
            />
        </Box>
    );
}

export default StaffView;